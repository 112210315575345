*, body {
  box-sizing: border-box;
  margin:0;
  padding:0;
}

.active-modal {
  overflow: hidden;
  height: 100%;
  color:rgb(151, 255, 203)
}
.PhoneInputCountryIconImg{
display: none !important;
}
.PhoneInputCountry{
  width: 35% !important;
}
@font-face {
  font-family: "SF-Pro-Regular";
  src: local("SF-Pro-Regular"),
   url("./assets/fonts/sf-pro/SF-Pro-Display-Regular.ttf") format("truetype");
  } 
  @font-face {
    font-family: "SF-Pro-Bold";
    src: local("SF-Pro-Bold"),
     url("./assets/fonts/sf-pro/SF-Pro-Display-Bold.ttf") format("truetype");
  }
  @font-face {
      font-family: "SF-Pro-Semibold";
      src: local("Eina01-Semibold"),
       url("./assets/fonts/sf-pro/SF-Pro-Display-Semibold.ttf") format("truetype");
  }
  @font-face {
    font-family: "SF-Pro-light";
    src: local("SF-Pro-light"),
     url("./assets/fonts/sf-pro/SF-Pro-Display-Light.ttf") format("truetype");
  }

  @font-face {
    font-family: 'SF-Pro-Display-Regular';
    src: url("./assets/fonts/sf-pro/SF-Pro-Display-Regular.ttf") format("truetype");;
  }

  @font-face {
    font-family: "Eina01-Regular";
    src: local("Eina01-Regular"),
     url("./assets/fonts/Eina01/Eina01-Regular.otf") format("truetype");
    } 
    @font-face {
      font-family: "Eina01-Bold";
      src: local("Eina01-Bold"),
       url("./assets/fonts/Eina01/Eina01-Bold.ttf") format("truetype");
    }
    @font-face {
        font-family: "Eina01-Semibold";
        src: local("Eina01-Semibold"),
         url("./assets/fonts/Eina01/Eina01-SemiBold.ttf") format("truetype");
    }
    @font-face {
      font-family: "Eina01-light";
      src: local("Eina01-light"),
       url("./assets/fonts/Eina01/Eina01-Light.ttf") format("truetype");
    }

  h1, h2,h3,h4, h5,h6{
    font-family: "SF-Pro-Semibold";
    font-weight: 100;
  }
 
  p,  label{
    font-family: "SF-Pro-Regular";

  }
 a, button, span{
    font-family: "SF-Pro-light";

  }
 .S9gUrf-YoZ4jf{
  margin: 0 auto!important;
  /* width: 100% !important; */


}
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #fff;
}
.react-tel-input .form-control{
  height: auto !important;
  padding-top: .8rem !important;
  padding-bottom: .8rem !important;
  border-radius: 0px !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background:  transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:  transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(252, 249, 249, 0.556);
}

input:-internal-autofill-selected {
  background: transparent !important;
}

.bg-gradient{
  background: linear-gradient(269.48deg, #FCBE5A -14.66%, #FC5059 38.46%);

}
.bord-gr{
  border-bottom: 4px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(269.48deg, #FCBE5A -14.66%, #FC5059 38.46%);
  border-radius: 25px;
}